import { render, staticRenderFns } from "./chatcard.vue?vue&type=template&id=2d9a4513&scoped=true&"
import script from "./chatcard.vue?vue&type=script&lang=js&"
export * from "./chatcard.vue?vue&type=script&lang=js&"
import style0 from "./chatcard.vue?vue&type=style&index=0&lang=css&"
import style1 from "./chatcard.vue?vue&type=style&index=1&id=2d9a4513&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d9a4513",
  null
  
)

export default component.exports