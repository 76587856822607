<template>
  <div class="">
    <div class="cityjz">
      <div
        :class="activenum == item.id ? 'activeclass' : ''"
        v-for="(item, index) in city"
        :key="index"
        @click="changecity(item.id)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>

    <!-- 聊天卡 -->
    <chatcard v-if="activenum == 1"></chatcard>
    <!-- <短信提醒卡 -->
    <shortmessage v-else-if="activenum == 2"></shortmessage>
    <!-- 套餐购买 -->
    <packages v-else-if="activenum == 3"></packages>
  </div>
</template>

<script>
import chatcard from "/src/components/prop/chatcard.vue"; //聊天卡
import packages from "/src/components/prop/packages.vue"; //短信提醒卡
import shortmessage from "/src/components/prop/shortmessage.vue"; //短信提醒卡
export default {
  components: {
    chatcard,
    shortmessage,
    packages,
  },
  data() {
    return {
      city: [
        { name: "聊天卡", id: 1 },
        { name: "短信提醒卡", id: 2 },
        { name: "套餐购买", id: 3 },
      ],
      activenum: 1,
    };
  },
  created() {},
  methods: {
    changecity(id) {
      this.activenum = id;
    },
  },
};
</script>

<style lang="less" scoped>
.cityjz {
  margin-bottom: 20px;
  // height: 50px;
  display: flex;
  align-items: center;
  div:hover {
    cursor: pointer;
  }
  div {
    // height: 50px;
    margin-right: 30px;
    color: #666666;
  }
  .activeclass {
    color: #111111;
    position: relative;
    font-weight: 600;
  }
  .activeclass :after {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    bottom: -6px;
    background: #00bcff;
    left: 30%;
  }
}
</style>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 20px !important;
}
</style>
