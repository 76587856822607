<template>
  <div class="">
    <el-card shadow="always" class="box-card all">
      <div class="bc-tit font20">我的道具</div>
      <el-card shadow="always" class="daojutype">
        <div class="daoju-main">
          <el-card shadow="always" class="font18">
            <div class="cardmain active">
              <div class="c-one">聊天卡</div>
              <div class="c-two">{{ userInfo.chatCount }}</div>
              <div class="c-three font16">当前剩余个数</div>
            </div>
          </el-card>
          <el-card shadow="always" class="font18">
            <div class="cardmain">
              <div class="c-one">短信提醒卡</div>
              <div class="c-two">{{ userInfo.messageCount }}</div>
              <div class="c-three font16">当前剩余个数</div>
            </div>
          </el-card>
        </div>
        <div class="daoju-btn">
          <el-button type="primary" class="btn" @click="DialogVisible = true"
            >定制套餐</el-button
          >
        </div>
      </el-card>
      <div class="line"></div>
      <el-card class="introduce">
        <div class="i-tit font18">聊天卡介绍</div>
        <div class="sequence font14">
          <div class="">用于对求职者发起沟通</div>
        </div>
        <div class="typelist">
          <div
            class="typelist-main"
            :class="typenum == itemes.id ? 'activebg' : ''"
            v-for="(itemes, index3) in typelist"
            :key="index3"
            @click="choosetype(itemes)"
          >
            <div class="font20">{{ itemes.count }}张</div>
            <el-divider></el-divider>
            <div class="font24 price">
              <span class="font14">￥</span>{{ itemes.money }}
            </div>
            <img
              src="../../assets/images/prop/c-goucou.png"
              v-if="itemes.id == typenum"
            />
          </div>
        </div>
        <div class="pay">
          <div class="i-tit font18">支付方式</div>
          <div class="total font16">支付金额：￥{{ payMoney }}</div>
          <div class="payli">
            <div
              class=""
              style="margin-top: 20px"
              v-for="(p, index3) in payli"
              :key="index3"
            >
              <img
                class="gou"
                src="../../assets/images/prop/gou-orange.png"
                v-if="payradio == p.id"
                @click="payradio = p.id"
              />
              <img
                class="gou"
                src="../../assets/images/prop/gou-grey.png"
                v-else
                @click="payradio = p.id"
              />
              <img
                class="zfb"
                src="../../assets/images/prop/zihfubao.png"
                v-show="p.id == 1"
              />
              <img
                class="wx"
                src="../../assets/images/prop/weixin.png"
                v-show="p.id == 2"
              />
              <div class="font16">{{ p.name }}</div>
            </div>
          </div>
          <div class="p-btn">
            <el-button type="primary" @click="pay">去支付</el-button>
          </div>
        </div>
      </el-card>
    </el-card>
    <!-- 定制道具套餐弹窗 -->
    <el-dialog
      :visible.sync="DialogVisible"
      width="650px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="apply-box-out"
    >
      <div class="apply-box">
        <span class="closeicon" @click="closeDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">定制道具套餐</div>
        <div class="frombox">
          <el-input
            v-model="userphone"
            placeholder="输入您的联系方式"
          ></el-input>
          <div class="apply_tishi">
            <div class="p-img font16">
              <i class="el-icon-warning"></i>
            </div>
            <div class="p-cont font14">
              我们将短时间内与您取得联系，请您注意来电，您也可以主动
              联系平台客服，电话：0571-85109006
            </div>
          </div>
          <div class="upapply-btn">
            <el-button type="primary" @click="customPack">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 支付成功弹窗 -->
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="buyDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">支付成功</div>
        <div class="buy-btn">
          <el-button type="primary" @click="tobuylist()"
            >查看购买记录</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 二维码弹窗 -->
    <el-dialog
      :visible.sync="payPoputShow"
      width="600px"
      center
      :destroy-on-close="true"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <span class="closeicon" @click="closebox()"
        ><i class="el-icon-error"></i
      ></span>
      <div class="payPoput" style="padding: 40px 0">
        <span>请打开{{ payradio == 1 ? "支付宝" : "微信" }}扫描下方二维码</span>
        <!-- <canvas id="canvas"></canvas> -->
        <div style="margin: 20px 0" id="qrcode" ref="qrcode"></div>
      </div>
    </el-dialog>
    <!-- <div class="payPoput" v-if="payPoputShow" @click.self="payPoputShow = false">
			<canvas id="canvas"></canvas>
		</div> -->
    <el-dialog
      title="提示"
      :visible.sync="aliDialogVisible"
      width="30%"
      style="padding: 20px"
    >
      <span>请在新打开的页面进行支付</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paySuccess">支付失败</el-button>
        <el-button type="primary" @click="paySuccess">支付成功</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      typelist: [],
      typenum: 0,
      payradio: 1,
      payli: [
        { id: 1, name: "支付宝支付" },
        { id: 2, name: "微信支付" },
      ],
      DialogVisible: false,
      aliDialogVisible: false,

      userphone: "",
      buyDialogVisible: false, //购买成功
      payMoney: "",
      payPoputShow: false,
      userInfo: {},
      timer: "",
      orderId: null,
    };
  },
  created() {
    this.getUserInfo();
    this.getList();
  },
  methods: {
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        this.userInfo = res.data.company;
      });
    },
    getList() {
      this.$api.getPropsList("get", { type: 1 }).then((res) => {
        this.typelist = res.data;
        this.typenum = res.data[0].id;
        this.payMoney = res.data[0].money;
      });
    },
    paySuccess() {
      this.aliDialogVisible = false;
      clearInterval(this.timer);
      this.payNotify(this.orderId);
    },
    pay() {
      // this.buyDialogVisible=true
      if (this.payradio == 1) {
        this.aliDialogVisible = true;

        this.$api.aliPay("post", { productId: this.typenum }).then((res) => {
          let routerData = this.$router.resolve({
            path: "/payGateWay",
            query: { htmlData: res.data },
          });
          // 打开新页面
          window.open(routerData.href, "_ blank");
          this.orderId = res.count;

          this.timer = setInterval(() => {
            this.payNotify(res.count);
          }, 2000);
          //   const h = this.$createElement;
          //   this.$msgbox({
          //     title: "购买道具",
          //     message: h("div", null, [
          //       h("div", null, "请在新打开的页面进行支付 "),
          //       h(
          //         "div",
          //         { style: "color: #33333399;font-size:12px" },
          //         "支付完成后，根据您的情况点击下面按钮"
          //       ),
          //     ]),
          //     showCancelButton: true,
          //     confirmButtonText: "支付成功",
          //     cancelButtonText: "支付失败",
          //     beforeClose: (action, instance, done) => {
          //       if (action === "confirm") {
          //         done();
          //         this.buyDialogVisible = true;
          //       } else {
          //         done();
          //       }
          //     },
          //   }).then(() => {});
        });
      } else {
        this.$api.wxPay("post", { productId: this.typenum }).then((res) => {
          let text = res.data.image;
          this.$nextTick(function () {
            setTimeout(() => {
              new QRCode(this.$refs.qrcode, {
                text: text, //this.link, // 需要转换为二维码的内容
                width: 200,
                height: 200,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
              // this.$refs.qrcode.innerHTML = ''; //清除二维码方法一
            }, 500);
          });
          this.orderId = res.count;

          this.payPoputShow = true;
          this.timer = setInterval(() => {
            this.payNotify(res.count);
          }, 2000);
        });
      }
    },

    payNotify(orderId) {
      var that = this;
      that.$api.payInfo("get", { orderId: orderId }).then((res) => {
        // console.log(res);
        if (res.data.payStatus == 1) {
          clearInterval(that.timer);

          that.payPoputShow = false;
          that.buyDialogVisible = true;
          that.aliDialogVisible = false;
          window.location.reload();
        }
      });
    },
    choosetype(data) {
      this.typenum = data.id;
      this.payMoney = data.money;
    },
    customPack() {
      if (this.userphone) {
        let config = {
          phone: this.userphone,
          type: 1,
        };
        this.$api.customPack("post", config).then(() => {
          this.DialogVisible = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    closeDialogbox() {
      this.DialogVisible = false;
    },
    closebox() {
      this.payPoputShow = false;
    },
    tobuylist() {
      this.buyDialogVisible = false;
      this.$router.push("/buyrecord");
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 30px;
}
.payPoput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    font-size: 18px;
    line-height: 25px;
    margin-top: 10px;
  }
  #canvas {
    width: 300px !important;
    height: 300px !important;
  }
}
.all {
  .bc-tit {
  }

  .daojutype {
    margin-top: 30px;
    .daoju-main {
      display: flex;
      align-items: center;
      /deep/.el-card {
        border-radius: 0px;
        width: 250px;
        margin-right: 40px;
      }
      /deep/.el-card__body {
        padding: 0;
      }
      .cardmain {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .c-one {
          background-color: #bbbbbb;
          color: #ffffff;
          width: 100%;
          text-align: center;
          padding: 10px 0;
        }
        .c-two {
          color: #666666;
          padding: 20px 0;
          font-size: 32px;
        }
        .c-three {
          color: #999999;
          padding-bottom: 25px;
        }
      }
      .active {
        .c-one {
          background-color: #ff8400;
        }
        .c-two {
          color: #111111;
        }
        .c-three {
          color: #666666;
        }
      }
    }
    .daoju-btn {
      /deep/ .el-button {
        border-radius: 0px;
        width: 330px;
        margin-top: 30px;
        background: #00bcff;
        border: none;
      }
    }
  }
}
.line {
  width: 100%;
  height: 15px;
  background-color: #f3f3f3;
}
.introduce {
  .i-tit {
    margin: 0 0 20px 0;
    color: #111111;
  }
  .sequence {
    color: #666666;
    line-height: 1.8;
  }
  .typelist {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .activebg {
      background-color: #fff9f4 !important;
      /deep/.el-divider--horizontal {
        background-color: #ff8400 !important;
      }
      .price {
        color: #ff8400 !important;
      }
    }
    .typelist-main:hover {
      cursor: pointer;
    }
    .typelist-main {
      position: relative;

      background-color: #f3f3f3;
      width: 110px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      padding: 20px 47px;
      line-height: 1.5;
      /deep/.el-divider--horizontal {
        margin: 20px;
      }
      div:first-child {
        color: #111111;
      }
      div:last-child {
        color: #666666;
      }
      img {
        width: 36px;
        height: 32px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .pay {
    .total {
      color: #ff4444;
    }
    .p-btn {
      /deep/ .el-button {
        border-radius: 0px;
        width: 330px;
        margin-top: 30px;
        background: #00bcff;
        border: none;
      }
    }
    .payli {
      div {
        display: flex;
        align-items: center;
        color: #111111;

        .gou {
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }
        .zfb {
          width: 34px;
          height: 34px;
          margin-right: 11px;
        }
        .wx {
          width: 35px;
          height: 30px;
          margin-right: 11px;
        }
      }
    }
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.apply-box-out {
  .apply-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      text-align: center;
      padding: 58px 100px 30px 100px;

      .upapply-btn {
        margin-top: 60px;
        button {
          width: 100%;
          height: 40px;
          background-color: #00bcff;
          border: none;
        }
      }
      .apply_tishi {
        display: flex;
        margin-top: 10px;
        .p-img {
          margin: 0 8px;
          color: #ff4444;
        }
        .p-cont {
          display: flex;
          flex: 1;
          color: #666666;
          line-height: 1.5;
          text-align: left;
        }
      }
    }
  }
}

.updown_box {
  line-height: 2.5;
  padding: 70px 0 50px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .buy-btn {
    text-align: center;
    margin-top: 40px;
    /deep/ .el-button {
      border-radius: 0px;
      width: 400px;
      height: 40px;
      background-color: #00bcff;
      border: none;
    }
  }
}
</style>
